import React from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import ToggleChannelFavoriteButton from "components/shared/favoriteButtons/ToggleChannelFavoriteButton";
import { useChannel } from "components/channels/api";

export default function ChannelPage() {
  const { slug } = useParams();
  const { data: channel, isLoading } = useChannel(slug);

  return (
    <PageTitle title={isLoading ? I18n.t("js.loading") : channel?.title || ""}>
      {channel && (
        <ToggleChannelFavoriteButton
          channelSlug={channel.slug}
          favorited={channel.i.favorited}
        />
      )}
    </PageTitle>
  );
}
