import React, { Fragment } from "react";
import { get, map, isEqual, isEmpty, find } from "lodash";
import moment from "moment";
import { showNameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import { Message } from "../@types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import FileIcon from "components/shared/FileIcon";

type Props = {
  currentMemberId: string;
  attachments: Array<{
    id: string;
    original_filename: string;
    extension: string;
  }>;
  isConversation: boolean;
} & Message;

function MessageContainer({
  author,
  created_at,
  currentMemberId,
  html_content,
  to,
  subject,
  attachments,
  id,
  isConversation,
}: Props): JSX.Element {
  const authorUrl = get(author, "html_url");
  const authorImageUrl = find(author.images, { width: 80, height: 80 })?.url;
  const authorName = get(author, "name");
  const date = moment(created_at).format("DD.MM.YYYY");
  const time = moment(created_at).format("HH:mm");

  return (
    <div
      className={classNames("border-box mb-10", {
        "bg-gray-50 rounded": isConversation,
      })}
    >
      <div className="detailsAuthor flex items-center p-4 border-neutral border-dashed border-b">
        <div className="activity-actor-image member-image mr-4">
          <a className="member-link" href={authorUrl}>
            <img className="w-10 h-10" src={authorImageUrl} alt={authorName} />
          </a>
        </div>
        <div className="flex-1">
          <a href={authorUrl} className="member-link font-semibold">
            {authorName}
          </a>

          <div className={"text-sm"}>
            <span>{I18n.t("js.messages.message.to")}: </span>
            {to &&
              map(to, (recipient, i) => (
                <Fragment key={recipient.id}>
                  <a href={recipient.html_url}>
                    {isEqual(currentMemberId, recipient.id)
                      ? I18n.t("js.messages.search_result_row.myself")
                      : showNameWithProfileField({
                          name: recipient.name,
                          detectionProfileField:
                            recipient.detection_profile_field,
                          isExternal: recipient.is_external,
                        })}
                  </a>
                  {i != to.length - 1 ? ", " : null}
                </Fragment>
              ))}
          </div>
        </div>

        <div className="detailsMeta flex gap-2 text-sm">
          {!isEmpty(attachments) ? (
            <i className="fa fa-paperclip text-muted" />
          ) : null}
          {date} {time}
        </div>
      </div>
      <div className={"p-2 my-2"}>
        <div className="messageContent p-2">
          <span className="messageSubject font-bold">{subject}</span>
          <p
            className="pt-2"
            dangerouslySetInnerHTML={{
              __html: html_content,
            }}
          />
        </div>

        {!isEmpty(attachments) ? (
          <div className="attachmentsContainer pt-4 px-2 pb-2 flex flex-col gap-6">
            {map(attachments, (a) => {
              return (
                <div className="messageAttachment flex" key={a.id}>
                  <div className="attachmentIcon">
                    <FileIcon extension={a.extension} />
                  </div>
                  <a href={`/messages/${id}/files/${get(a, "id")}`}>
                    {get(a, "original_filename")}
                  </a>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

      {isConversation ? (
        <div className="border-neutral border-dashed border-t">
          <a href={`/messages/${id}`} className={"btn btn-link block"}>
            <FontAwesomeIcon icon={regular("eye")} className={"mr-2"} />
            {I18n.t("js.messages.message.more")}
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default MessageContainer;
