import { enGB, de, es, fr } from "date-fns/locale";
import { format } from "date-fns";

const LOCALE_LOOKUP = {
  de_DU: de,
  de: de,
  en: enGB,
  es: es,
  fr: fr,
};

export default function formatDate(
  date: Date | number,
  dateFormat: string,
  options = {},
) {
  return format(date, dateFormat, {
    locale: LOCALE_LOOKUP[I18n.currentLocale()],
    ...options,
  });
}
