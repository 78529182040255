import { truncate } from "lodash";
import React, { useState } from "react";

export default function AppointmentDescription({
  description,
  isRendered,
}: {
  description: string;
  isRendered: boolean;
}) {
  const [truncateDescription, setTruncateDescription] = useState(true);

  const handleReadMore = (e) => {
    e.preventDefault();
    setTruncateDescription(false);
  };

  const truncateText = (text: string) => {
    return truncate(text, {
      length: 500,
      omission: `... <a href="#">${I18n.t("activitystreams.more")}</a>`,
    });
  };

  const maybeTruncatedDescription = truncateDescription
    ? truncateText(description)
    : description;

  return isRendered ? (
    <div
      className="detail-description prose prose-sm"
      onClick={handleReadMore}
      dangerouslySetInnerHTML={{
        __html: maybeTruncatedDescription,
      }}
    />
  ) : (
    <p className="whitespace-pre overflow-auto">{maybeTruncatedDescription}</p>
  );
}
