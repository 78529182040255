import ParticipantsList from "components/appointments/ParticipantsList";
import React, { useState } from "react";
import {
  Appointment,
  AppointmentInvite,
  Participant,
} from "../../../@types/appointments";
import { escape, includes, map } from "lodash";
import classNames from "classnames";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import RecurringAppointmentDecisionModal from "components/shared/RecurringAppointmentDecisionModal";
import Translation from "components/shared/Translation";

type AppointmentParticipationsArgs = {
  participants: Participant[];
  canShowAttendees: boolean;
  invitedConsumers: Appointment["invited_consumers"];
  teamsOnlyParticipantsCount: number;
  canOpt: boolean;
  myInvite?: AppointmentInvite;
  myParticipation?: Participant;
  participationLocked: boolean;
  totalAttendeesExceeded: boolean;
  showRecurringDecisionModal: boolean;
  appointmentId: string;
  refetch: () => void;
};

export default function AppointmentParticipations({
  appointmentId,
  participants,
  canShowAttendees,
  invitedConsumers,
  teamsOnlyParticipantsCount,
  canOpt,
  myInvite,
  myParticipation,
  participationLocked,
  totalAttendeesExceeded,
  showRecurringDecisionModal,
  refetch,
}: AppointmentParticipationsArgs) {
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState<
    "open" | "accepted" | "declined" | ""
  >("");
  const { mutate: updateStatus, isLoading } = useMutation<
    unknown,
    Error,
    { status: "open" | "accepted" | "declined" | ""; apply_to?: string }
  >(
    ({ status, apply_to }) =>
      fetchApi(
        `/appointments/${appointmentId}/participants/${myParticipation?.id}`,
        { method: "PUT", body: { status, apply_to } },
      ),
    { onSuccess: () => refetch() },
  );

  function onUpdateStatus(
    e: React.MouseEvent<HTMLButtonElement>,
    status: "open" | "accepted" | "declined",
  ) {
    e.preventDefault();
    setUpdatingStatus(status);

    if (showRecurringDecisionModal) {
      setShowDecisionModal(true);
    } else {
      updateStatus({ status });
    }
  }

  const localeKey = {
    accepted: "accept",
    declined: "decline",
    open: "no_answer",
  };

  return (
    <>
      <RecurringAppointmentDecisionModal
        showModal={showDecisionModal}
        onClose={() => {
          setShowDecisionModal(false);
        }}
        handleDecision={(apply_to: string) => {
          updateStatus({ status: updatingStatus, apply_to });
          setShowDecisionModal(false);
        }}
      />
      {myInvite && (
        <div className="widget">
          <Translation
            translation="js.calendars.appointment.rsvp.invited_by_html"
            variables={{
              inviter: `<a href="${escape(myInvite.author?.path)}">${escape(
                myInvite.author?.name,
              )}</a>`,
            }}
          />
        </div>
      )}

      {canOpt && (
        <div className="widget">
          <div
            className="participation-actions btn-group-vertical w-full"
            data-toggle="buttons-radio"
          >
            {map(
              ["accepted", "declined", "open"],
              (statusKey: "open" | "accepted" | "declined") => (
                <button
                  key={statusKey}
                  className={classNames("btn text-left btn-light", {
                    active: myParticipation?.status === statusKey,
                    "btn-alert-success":
                      myParticipation?.status === statusKey &&
                      statusKey === "accepted",
                    "btn-danger":
                      myParticipation?.status === statusKey &&
                      statusKey === "declined",
                    "btn-gray":
                      myParticipation?.status === statusKey &&
                      includes(["open", "invited"], statusKey),
                  })}
                  onClick={(e) => onUpdateStatus(e, statusKey)}
                  disabled={
                    participationLocked ||
                    (statusKey === "accepted" && totalAttendeesExceeded)
                  }
                >
                  {isLoading && updatingStatus === statusKey && (
                    <>
                      <FontAwesomeIcon icon={regular("spinner")} spin />{" "}
                    </>
                  )}
                  {I18n.t(
                    `js.calendars.appointment.rsvp.${localeKey[statusKey]}`,
                  )}
                </button>
              ),
            )}
          </div>
        </div>
      )}
      <ParticipantsList
        participants={participants}
        showParticipants={canShowAttendees}
        consumersCount={invitedConsumers}
        teamsOnlyParticipantsCount={teamsOnlyParticipantsCount}
      />
    </>
  );
}
