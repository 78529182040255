import React, { lazy, Suspense } from "react";
import { RouteObject } from "helpers/tixxt-router";
import ChannelsPage from "./ChannelsPage";
import ChannelPage from "components/channels/ChannelPage";

const ChannelNewPage = lazy(() => import("components/channels/ChannelNewPage"));
const ChannelEditPage = lazy(
  () => import("components/channels/ChannelEditPage"),
);

const channelRoutes: RouteObject[] = [
  { path: "/channels", element: <ChannelsPage /> },
  {
    path: "/channels/new",
    element: (
      <Suspense fallback={"Loading..."}>
        <ChannelNewPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:slug/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <ChannelEditPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:slug",
    element: <ChannelPage />,
  },
];

export default channelRoutes;
