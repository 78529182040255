import React, { useState } from "react";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import { useChannels } from "components/channels/api";
import { closeUserbar } from "../../layout/togglebar";
import { isEmpty, some } from "lodash";

function persistOpenState(open: boolean, section: string) {
  localStorage.setItem(`${section}/open`, open.toString());
}

function loadOpenState(section: string) {
  const item = localStorage.getItem(`${section}/open`);
  return item === null ? null : item === "true";
}

function EmptySubscribedHint() {
  return (
    <div className={"text-center"}>
      <div className="opacity-60 userbar-link-hint">
        {I18n.t("js.channels.userbar.subscribed.empty")}
      </div>
      <a
        className="opacity-80"
        href={`/directory/channels`}
        onClick={closeUserbar}
      >
        {I18n.t("js.channels.userbar.subscribed.call_to_action")}
      </a>
    </div>
  );
}

function EmptyRecentHint() {
  return (
    <div className={"text-center"}>
      <div className="opacity-60 userbar-link-hint">
        {I18n.t("js.channels.userbar.recent.empty")}
      </div>
    </div>
  );
}

function EmptyPopularHint() {
  return (
    <div className={"text-center"}>
      <div className="opacity-60 userbar-link-hint">
        {I18n.t("js.channels.userbar.popular.empty")}
      </div>
    </div>
  );
}

const DISPLAY_LIMITS = {
  subscribed: 50,
  recent: 10,
  popular: 25,
};

export default function ChannelsUserbar() {
  const { data: channelsData, isLoading } = useChannels();
  const channelTypes = ["subscribed", "recent", "popular"];
  const channelData = channelTypes.map((type) => ({
    type,
    isLoading,
    data: channelsData?.[type] ?? [],
    openState: loadOpenState(`${type}ChannelsSection`),
  }));

  const [openStates, setOpenStates] = useState(
    channelData.reduce((acc, { type, data, openState }) => {
      acc[type] = openState === null ? !isEmpty(data.data) : openState;
      return acc;
    }, {}),
  );

  const handleSetOpen = (type, open) => {
    setOpenStates((prev) => ({ ...prev, [type]: open }));
    persistOpenState(open, `${type}ChannelsSection`);
  };

  const renderSection = (type, channels, isLoading) => {
    const titleKey = `js.channels.userbar.${type}.section_title`;
    const emptyHint =
      type === "subscribed" ? (
        <EmptySubscribedHint />
      ) : type === "recent" ? (
        <EmptyRecentHint />
      ) : (
        <EmptyPopularHint />
      );
    const showMoreKey = `js.channels.userbar.${type}.show_more`;

    return (
      <UserbarSection
        key={type}
        title={I18n.t(titleKey)}
        isOpen={openStates[type]}
        setIsOpen={(open) => handleSetOpen(type, open)}
        hasFavorites={
          type === "subscribed" && some(channels, { i: { favorited: true } })
        }
        collapsible
      >
        {(channels ?? []).length === 0 &&
          (isLoading ? (
            <UserbarLink key={0} to={`/channels`} active={false}>
              {I18n.t("js.loading")}
            </UserbarLink>
          ) : (
            emptyHint
          ))}
        {(channels ?? []).slice(0, DISPLAY_LIMITS[type]).map((channel) => (
          <UserbarLink
            key={channel.id}
            to={`/channels/${channel.slug}`}
            favorited={type === "subscribed" && channel.i.favorited}
            showWhileClosed={type === "subscribed" && channel.i.favorited}
            collapsed={!openStates[type]}
          >
            {channel.title}
          </UserbarLink>
        ))}
        {(channels ?? []).length > DISPLAY_LIMITS[type] &&
          type === "subscribed" && (
            <li className="userbar-link section-content__hide flex border-t pt-1">
              <a
                className="flex-1 opacity-80 px-2 text-center hover:no-underline"
                href={`/directory/channels?only_subscribed=true`}
                onClick={closeUserbar}
              >
                {I18n.t(showMoreKey)}
              </a>
            </li>
          )}
        {(type === "recent" || type === "popular") && (
          <li className="userbar-link section-content__hide flex border-t pt-1">
            <a
              className="flex-1 opacity-80 px-2 text-center hover:no-underline"
              href={`/directory/channels`}
              onClick={closeUserbar}
            >
              {I18n.t(showMoreKey)}
            </a>
          </li>
        )}
      </UserbarSection>
    );
  };

  return (
    <>
      <TixxtModuleSwitcher activeModule="channels" />
      <ContextNavigation>
        {channelData.map(({ type, isLoading, data: channels }) =>
          renderSection(type, channels, isLoading),
        )}
      </ContextNavigation>
    </>
  );
}
